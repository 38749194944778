import * as React from "react"
import Icon from "../Icon/Icon"
import "./Video.css"

const Video = ({ title, description, videoId, thumbnail }) => {
  const [thumbnailVisible, setThumbnailVisible] = React.useState(true)

  return (
    <div className="video">
      <iframe
        className={thumbnailVisible ? "not-tabbable" : ""}
        width="1200"
        height="675"
        src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&autoplay=${
          thumbnailVisible ? "0" : "1"
        }`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {thumbnailVisible && (
        <>
          <img
            alt={`Thumbnail for video "${title}"`}
            className="video-thumbnail"
            src={
              thumbnail ??
              `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            }
          />
          <div className="video-overlay">
            <button
              className="video-play"
              onClick={() => setThumbnailVisible(false)}
              title="Play video"
            >
              <Icon name="play" tooltip="Play video" />
            </button>
            <div className="video-overlay-content">
              <h3>{title}</h3>
              {description}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Video
