import * as React from "react"
import "./ButtonStyledLink.css"

const ButtonStyledLink = ({ primary, small, children, href }) => (
  <a
    className={`button button--${primary ? "primary" : "outline"} ${
      small ? "button--small" : ""
    }`}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

export default ButtonStyledLink
