import { graphql } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import CompaniesSection from "../components/Workshop/CompaniesSection/CompaniesSection"
import FAQSection from "../components/Workshop/FAQSection/FAQSection"
import PricingTable from "../components/Workshop/PricingTable/PricingTable"
import TestimonialsSection from "../components/Workshop/TestimonialsSection/TestimonialsSection"
import WorkshopHero from "../components/Workshop/WorkshopHero/WorkshopHero"
import WorkshopIntroSection from "../components/Workshop/WorkshopIntroSection/WorkshopIntroSection"
import WorkshopWhatsIncludedSection from "../components/Workshop/WorkshopWhatsIncludedSection/WorkshopWhatsIncludedSection"
import WorkshopWhatToExpectSection from "../components/Workshop/WorkshopWhatToExpectSection/WorkshopWhatToExpectSection"
import "../workshop.css"

const Workshops = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Code Review Workshops" />

      <article>
        <WorkshopHero />
        <br />
        <br />
        <WorkshopIntroSection />
        <CompaniesSection />
        <WorkshopWhatsIncludedSection />
        <TestimonialsSection reviewCount={2} withBackground />
        <WorkshopWhatToExpectSection />
        <TestimonialsSection compact reviewCount={8} offset={2} />
        <PricingTable />
        <FAQSection />
      </article>
    </Layout>
  )
}

export default Workshops

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
