import * as React from "react"
import "./FadeInElement.css"

const FadeInElement = ({ children }) => {
  let [isVisible, setVisible] = React.useState(true)
  const domRef = React.useRef()

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    const ref = domRef.current
    observer.observe(ref)
    return () => observer.unobserve(ref)
  }, [])

  return (
    <div
      className={`fade-in-element ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export default FadeInElement
