import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./CompaniesSection.css"


const CompaniesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      spotify: file(relativePath: { eq: "company-logos/spotify.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      exchange: file(relativePath: { eq: "uploads/2019/04/microsoft-exchange-logo_s.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      codestream: file(relativePath: { eq: "uploads/2020/05/codestream_logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      office365: file(relativePath: { eq: "uploads/2019/04/Office-365-logo_s.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      windows: file(relativePath: { eq: "uploads/2019/04/ps_windows_01_s.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      blip: file(relativePath: { eq: "uploads/2020/05/blip_logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      visualStudio: file(relativePath: { eq: "uploads/2019/04/VisualStudio_s.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      nationalInstruments: file(relativePath: { eq: "uploads/2020/05/National_Instruments_logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      microsoft: file(relativePath: { eq: "uploads/2019/12/microsofts-logo-gets-a-makeover.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      wix: file(relativePath: { eq: "uploads/2020/05/wix_logo-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      automattic: file(relativePath: { eq: "uploads/2020/05/automattic_logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      flutterEntertainment: file(relativePath: { eq: "uploads/2020/05/Flutter_Entertainment-lg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
    }
  `)

  const companies = [
    { name: "Spotify", logo: getImage(data.spotify) },
    { name: "Microsoft Exchange", logo: getImage(data.exchange) },
    { name: "codestream", logo: getImage(data.codestream) },
    { name: "Office 365", logo: getImage(data.office365) },
    { name: "Windows", logo: getImage(data.windows) },
    { name: "blip", logo: getImage(data.blip) },
    { name: "Visual Studio", logo: getImage(data.visualStudio) },
    { name: "National Instruments", logo: getImage(data.nationalInstruments) },
    { name: "Microsoft", logo: getImage(data.microsoft) },
    { name: "WIX", logo: getImage(data.wix) },
    { name: "Automattic", logo: getImage(data.automattic) },
    { name: "Flutter Entertainment", logo: getImage(data.flutterEntertainment) },
  ]
  return (
    <>
      <section className="workshop-section companies-section workshop-section--bg">
        <div className="content">
          <h2>Trusted by companies all over the globe.</h2>
          <div className="companies-section__grid">
            {companies.map(company => (
              <GatsbyImage
                  alt={company.name}
                  image={company.logo}
                  key={company.name}
                />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default CompaniesSection
