import * as React from "react"
// import "../../../workshop.css"
import FadeInElement from "../../FadeInElement/FadeInElement"
import "./WorkshopIntroSection.css"

const WorkshopIntroSection = () => {
  return (
    <>
      <section className="workshop-section workshop-section--text">
        <h2 className="visually-hidden">
          Introduction to Code Review Workshops
        </h2>
        <p>
          <b>
            Code Reviews are either a team’s superpower or an expensive
            bottleneck.
          </b>
        </p>
        <p>
          As a developer that practices code reviews, you are well aware of all
          the benefits of this engineering practice. Not only do code reviews
          increase the quality, readability, and maintainability of your
          codebase, but also foster collaboration of team members, increase
          knowledge sharing, mentoring and learning.{" "}
        </p>
        <p>
          <b>Still, code reviews also have their expensive drawbacks.</b> In the
          worst case, code reviews drastically reduce the productivity of an
          engineering team while yielding to questionable or mediocre code
          improvements at best. I've seen too many companies suffer from the
          costly drawbacks of code reviews. Engineers spend a significant amount
          of time doing code reviews while experiencing pitfalls such as large
          reviews, long review times, insecurities on review approaches, and
          varying quality of code review feedback.
        </p>
        <p>
          <b>
            In a code review workshop you learn how to implement a high-impact
            code review practice.
          </b>
        </p>
        <p>
          By understanding and measuring your code review pain points, and
          learning proven code review best practices, you learn how to transform
          your code reviews from nightmare to a valuable superpower.{" "}
        </p>
        <p>
          After the workshop, it will be crystal clear to you and your team how
          to effectively increase turnaround times while also ensuring
          high-quality review feedback. Learn from extensive code review
          research done by companies like Microsoft, Google, and Facebook. Get a
          rock-solid foundation on how to implement code reviews to ensure high
          productivity and high agility for your company.
        </p>
        <p>
          Gets to fast and effective code reviews, and improve collaboration and
          communication, by booking a code review workshop.
        </p>
      </section>
      <div className="workshop-solutions content">
        <FadeInElement>
          <div className="workshop-solutions__entry">
            <span>From superficial reviews missing bugs</span>
            <svg
              className="arrow"
              width="250"
              height="250"
              viewBox="0 0 250 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M113.95 221.5C121.1 219.502 134.189 211.474 129.349 195.344C123.3 175.183 109.55 171.368 107.35 144.668C105.15 117.967 134.849 109.249 144.199 124.506C153.549 139.763 132.099 151.751 121.1 133.769C112.3 119.384 115.6 64.9291 118.35 39.5M118.35 39.5L100.5 56.3922M118.35 39.5L132.75 58.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
                className="arrow-path"
              ></path>
              <style data-made-with="vivus-instant"></style>
            </svg>
            <span>to systematic + trustworthy reviews</span>
          </div>
        </FadeInElement>
        <FadeInElement>
          <div className="workshop-solutions__entry">
            <span>From long, frustrating discussions</span>
            <svg
              className="arrow"
              width="250"
              height="250"
              viewBox="0 0 250 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M113.95 221.5C121.1 219.502 134.189 211.474 129.349 195.344C123.3 175.183 109.55 171.368 107.35 144.668C105.15 117.967 134.849 109.249 144.199 124.506C153.549 139.763 132.099 151.751 121.1 133.769C112.3 119.384 115.6 64.9291 118.35 39.5M118.35 39.5L100.5 56.3922M118.35 39.5L132.75 58.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
                className="arrow-path"
              ></path>
              <style data-made-with="vivus-instant"></style>
            </svg>
            <span>to efficient reviews + fast progress</span>
          </div>
        </FadeInElement>
        <FadeInElement>
          <div className="workshop-solutions__entry">
            <span>From bulling and toxic atmospheres</span>
            <svg
              className="arrow"
              width="250"
              height="250"
              viewBox="0 0 250 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M113.95 221.5C121.1 219.502 134.189 211.474 129.349 195.344C123.3 175.183 109.55 171.368 107.35 144.668C105.15 117.967 134.849 109.249 144.199 124.506C153.549 139.763 132.099 151.751 121.1 133.769C112.3 119.384 115.6 64.9291 118.35 39.5M118.35 39.5L100.5 56.3922M118.35 39.5L132.75 58.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
                className="arrow-path"
              ></path>
              <style data-made-with="vivus-instant"></style>
            </svg>
            <span>to mentorship and learning cultures</span>
          </div>
        </FadeInElement>
      </div>
      <div>
        <FadeInElement>
        <div className="workshop-solutions__cta__heading">
          <h2>Make Your Code Reviews Rock</h2>
        </div>
          <div className="workshop-solutions__cta">
            <a
              className="button button--primary"
              href="https://forms.gle/9JywChAgPpjXgg1m7"
              target="_blank"
              rel="noreferrer"
            >
              Request Your Workshop
            </a>
          </div>
        </FadeInElement>
      </div>
    </>
  )
}

export default WorkshopIntroSection
