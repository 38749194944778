import * as React from "react"
import FadeInElement from "../../FadeInElement/FadeInElement"
// import "../../../workshop.css"
import "./WorkshopWhatToExpectSection.css"
import { StaticImage } from "gatsby-plugin-image"

const WorkshopWhatToExpectSection = () => {
  return (
    <>
      <section className="workshop-section what-to-expect-section">
        <h2>What to Expect?</h2>
        <p className="workshop-section--text">
          Through my code review workshops, your team gets to fast and effective
          code reviews, and improves their collaboration and communication. All
          workshops happen in <strong>small groups,</strong> are very{" "}
          <strong>interactive</strong> and filled with{" "}
          <strong>hands-on exercise.</strong>
        </p>
        <div className="what-to-expect-section__columns content">
          <div className="what-to-expect-section__img-wrapper">
            <FadeInElement>
              <div className="fake-image">
                <StaticImage src="../../../../static/illustrations/workshop/interactive.jpeg"  alt="Remote"/>
              </div>
            </FadeInElement>
          </div>
          <div className="what-to-expect-section__text-wrapper">
            <h3>Interactive and Hands-on</h3>
            <p>
              The code review workshops are packed with hands-on exercises,
              interactive discussions, little games, and equip the participants
              with actionable know-how.
            </p>
          </div>
        </div>
        <div className="what-to-expect-section__columns content">
          <div className="what-to-expect-section__img-wrapper">
            <FadeInElement>
              <div className="fake-image">
                <StaticImage src="../../../../static/illustrations/workshop/expect-2.jpeg"  alt="Remote"/>
              </div>
            </FadeInElement>
          </div>
          <div className="what-to-expect-section__text-wrapper">
            <h3>Play and Impactful</h3>
            <p>
              The code review trainings are playful, yet lead to tangible
              outcomes. Depending on the workshop, participants will be able to
              increase code review tum-around times, feedback quality, or
              collaboration.
            </p>
          </div>
        </div>
        <div className="what-to-expect-section__columns content">
          <div className="what-to-expect-section__img-wrapper">
            <FadeInElement>
              <div className="fake-image">
                <StaticImage src="../../../../static/illustrations/workshop/remote.png"  alt="Remote"/>
              </div>
            </FadeInElement>
          </div>
          <div className="what-to-expect-section__text-wrapper">
            <h3>Fully Remote</h3>
            <p>
              The code review workshops are held in a fully remote set-up via
              video conference tools such as Zoom. Participants need access to
              their own codebase and their code reviews.{" "}
            </p>
          </div>
        </div>
        <div className="what-to-expect-section__columns content">
          <div className="what-to-expect-section__img-wrapper">
            <FadeInElement>
              <div className="fake-image">
                <StaticImage src="../../../../static/illustrations/workshop/systematic.png"  alt="Remote"/>
              </div>
            </FadeInElement>
          </div>
          <div className="what-to-expect-section__text-wrapper">
            <h3>Research and Best Practices</h3>
            <p>
              The code review training is led by the leading code review expert,
              Dr. Michaela Greiler. Participants learn practices from industry
              leaders such as Microsoft, Google, Facebook and all advice is
              based on the latest research findings.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default WorkshopWhatToExpectSection
