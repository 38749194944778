import * as React from "react"
import ButtonStyledLink from "../../ButtonStyledLink/ButtonStyledLink"
import "./PricingTable.css"

const PricingTable = () => {
  return (
    <>
      <section className="workshop-section content pricing-section">
        <h2>Make Your Code Reviews Rock.</h2>
        <article className="pricing-table">
          <section className="pricing-table__tier">
            <h3>Focus</h3>
            <p>
              <strong>2-hour</strong> workshop focusing on <em>either</em>:
            </p>
            <ul>
              <li>
                Making code reviews faster and more effective
                <br />
                <strong>or</strong>
              </li>
              <li>Improving the feedback culture and collaboration.</li>
            </ul>
            <p className="pricing-table__price">€169</p>
            <p className="pricing-table__cta">
              <ButtonStyledLink
                small
                href="https://forms.gle/4j9RFXWb3Y6AHHTU7"
              >
                Request Your Workshop
              </ButtonStyledLink>
            </p>
            <p>
              <small>
                Min. number of participants is 10.
                <br />
                Add additional participants for <b>€129</b> per seat, up to 25 people per workshop.
                <br/>Fewer participants? Request a quote.
              </small>
            </p>
          </section>
          <section className="pricing-table__tier pricing-table__tier--highlighted">
            <h3>Excellence</h3>
            <p>
              <strong>3 x 2-hour</strong> workshop sessions.
            </p>
            <p>Focusing on:</p>
            <ul>
              <li>
                Making code reviews <strong>fast and more effective</strong>
              </li>
              <li>
                Improving code review feedback and{" "}
                <strong>collaboration</strong>
              </li>
              <li>
                Assessment of your team's code review{" "}
                <strong>pain points.</strong>
              </li>
            </ul>
            <p className="pricing-table__price">€429</p>
            <p className="pricing-table__cta">
              <ButtonStyledLink
                href="https://forms.gle/jWquAzFQUYWNT8727"
                primary
              >
                Request Your Workshop
              </ButtonStyledLink>
            </p>
            <p>
              <small>
                Min. number of participants is 10.
                <br />
                Add additional participants for <b>€349</b> per seat, up to 25 people per workshop.
                <br/>Fewer participants? Request a quote.
              </small>
            </p>
          </section>
          <section className="pricing-table__tier">
            <h3>Success</h3>
            <p>
              Everything in <strong>Excellence</strong>
              <br />+ Improvement coaching.
            </p>
            <ul>
              <li>
                <strong>In-depth analysis</strong> of your code review practice
              </li>
              <li>
                Including <strong>4 hours of coaching</strong> to
                help your team systematically improve their code review
                practice.
              </li>
            </ul>
            <p className="pricing-table__price">€699</p>
            <p className="pricing-table__cta">
              <ButtonStyledLink
                small
                href="https://forms.gle/jot8DN6sLfTa77dU6"
              >
                Request Your Workshop
              </ButtonStyledLink>
            </p>
            <p>
              <small>
                Min. number of participants is 10.
                <br />
                Add additional participants for <b>€499</b> per seat, up to 25 people per workshop.
                <br/>Fewer participants? Request a quote.
              </small>
            </p>
          </section>
        </article>
      </section>
    </>
  )
}

export default PricingTable
