import * as React from "react"
import FadeInElement from "../../FadeInElement/FadeInElement"
import "./FAQSection.css"

const faqs = [
  {
    question: "What happens during a Code Review Workshop?​",
    answer:
      "The workshops are designed to transform your code reviews from a bottleneck into a valuable superpower. They are highly interactive and include several hands-on exercises. All workshops are conducted remotely and in small groups. Participants will learn code review best practices from companies like Google and Microsoft, as well as empirically validated strategies to enhance their developer experience.​",
  },
  {
    question: "Why are Code Review Workshops Needed?",
    answer:
      "Developers spend a significant amount of time doing code reviews, often facing challenges such as large reviews, long review times, uncertainty about review approaches, and inconsistent quality of feedback. These issues can drastically reduce the productivity of an engineering team and result in questionable or mediocre code improvements.​",
  },
  {
    question: "What is the Outcome of the Code Review Training?​",
    answer:
      "After completing these workshops, engineers will know how to implement a high-impact code review strategy, improve code review turnaround times, enhance feedback quality, and communicate and collaborate respectfully within the team. Developers will leave with a concrete plan to improve code reviews in their team.​", 
  },
  {
    question: "Who Benefits from this Code Review Workshop?​",
    answer:
      "These workshops are beneficial for developers looking to improve their code review experience, whether they face slow turnaround times, low feedback quality, unclear guidelines, or toxic environments. They are also useful for product managers, engineering leads, and others actively participating in the code review process.",
  },
  {
    question: "Is the workshop suitable for senior developers?​",
    answer:
      "Yes, the workshop is highly suitable for senior developers. Their focus and role in the workshop are to level up their skills in mentoring and helping the team transform and improve their practices. Having senior and experienced developers attend is a significant advantage.",
  },
  {
    question: "Can the workshop be customized for our specific needs?​",
    answer:
      "Yes, the workshop can be customized to meet the specific needs and challenges of your team. Before each workshop, we will arrange a call with a few of your developers and leaders to understand your requirements and goals.",
  },
  {
    question: "When do the workshop take place?",
    answer:
      "The workshops are scheduled based on your availability. Please contact us to arrange a workshop.​",
  },
  {
    question: "What discounts are available?​",
    answer:
      "For groups larger than 15 people, please contact us for a custom offer.",
  },
  {
    question: "What happens if more than 25 developers want to do this workshop?​",
    answer:
      "Participants will be split into smaller groups, and the workshop will be repeated for each group.​",
  },
  {
    question: "What happens if we are less than 10 developers that want to do the workshop?​",
    answer:
      "You can still book the workshop, but the price per developer will increase. Please contact us for a quote. Or, consider teaming up with colleagues from another team or company.​",
  },
  {
    question: "Are prices inclusive of Value Added Tax (VAT)?​",
    answer:
      "All prices are exclusive of VAT. However, due to company transactions, most cases will be handled as reverse charge transactions.​",
  },
  {
    question: "Can we receive an invoice with the correct business address and reverse charge?​",
    answer:
      "Of course, we will provide an invoice that accurately detail the company's name, address, tax identification number, and any other required information.​",
  },
]

const FAQSection = () => {
  return (
    <>
      <section className="workshop-section content faq-section">
        <h2 className="faq-section__headline">Frequently Asked Questions</h2>
        <div className="faq__items">
          {faqs.map((item, index) => (
            <article className="faq__item" key={`item-${index}`}>
              <h3 className="faq__question">{item.question}</h3>
              {item.answer}
            </article>
          ))}
        </div>
      </section>
      <div>
        <FadeInElement>
        <div className="workshop-solutions__cta__heading">
        <h2>Still need more information?</h2>
          </div>
          <div className="workshop-solutions__cta">
            <a
              className="button button--primary"
              href="https://forms.gle/nNFonjDhenWN4g2B6"
              target="_blank"
              rel="noreferrer"
            >
              Request Complete Workshop Booklet
            </a>
          </div>
        </FadeInElement>
      </div>
    </>
  )
}

export default FAQSection
