import * as React from "react"
import "./WorkshopHero.css"
import { StaticImage } from "gatsby-plugin-image"

const WorkshopHero = () => {
  return (
    <>
      <section className="workshop-hero">
        <div className="fake-image"><StaticImage src="../../../../static/illustrations/workshop/workshop-illustrations2.png"  alt="Dr. Greiler giving a speech" /></div>
        <div className="fake-image fake-image--left"><StaticImage src="../../../../static/illustrations/workshop/workshop-illustrations.png"  alt="Dr. Greiler giving a speech" /></div>
        <div className="fake-image fake-image--right"><StaticImage src="../../../../static/illustrations/workshop/workshop-illustrations1.png"  alt="Dr. Greiler giving a speech" /></div>
        <div className="workshop-section--text">
          <h1>Make Code Reviews Your Superpower.</h1>
        </div>
      </section>
      <div className="workshop-hero__wave">
        <svg
          width="2048"
          height="115"
          viewBox="0 0 541.86665 30.427084"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m 542.39583,15.08125 c 0,0 -90.1745,-14.81666667 -135.6651,-14.81666667 -45.4906,0 -90.44338,9.83368267 -135.66511,14.81666667 C 225.8439,20.064234 180.90078,30.1625 135.40052,30.1625 89.900259,30.1625 -0.26458333,15.08125 -0.26458333,15.08125 L -29.938144,6.1237113 l 4.082474,-33.3402063 583.79381,1.360825 -0.68041,36.061856 z" />
        </svg>
      </div>
    </>
  )
}

export default WorkshopHero
