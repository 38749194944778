import * as React from "react"
// import "../../../workshop.css"
import "./WorkshopWhatsIncludedSection.css"

import VideoBanner from "../../VideoBanner/VideoBanner"
import FadeInElement from "../../FadeInElement/FadeInElement"
import { StaticImage } from "gatsby-plugin-image"

const WorkshopWhatsIncludedSection = () => {
  return (
    <>
      <section className="whats-included-section">
        <div className="workshop-section workshop-section--text">
          <FadeInElement>
          <div className="fake-image">
            <StaticImage src="../../../images/superpower-workshop.png"  alt="Super power workshop"/>
          </div>
          </FadeInElement>
          <h2>What's Included?</h2>
          <p>
            In this <strong>highly interactive</strong> code review workshop, I
            show you the most essential aspects of how to get to fast, yet
            effective code reviews. I reveal actionable strategies to overcome
            major pain points such as slow review turn-around times and low
            feedback quality.
          </p>
          <p>
            Another area that we will touch on is how to give respectful and
            constructive code review feedback, and I teach you a highly
            effective method to handle and resolve conflicts. This code review
            training series is dedicated to developers, product managers,
            engineering leads, and other folks actively participating in the
            code review process.
          </p>

          <h3>Topics</h3>
          <ul>
            <li>
              How does code review <strong>speed impact</strong> review{" "}
              <strong>quality?</strong>
            </li>
            <li>
              How to increase code <strong>review speed?</strong>
            </li>
            <li>
              How to increase <strong>feedback quality?</strong>
            </li>
            <li>
              Latest code review <strong>research and best practices</strong>
            </li>
            <li>
              How do <strong>Google, Microsoft, Facebook</strong> review code?
            </li>
            <li>
              Respectful, <strong>constructive</strong> code review feedback
            </li>
            <li>
              Positively <strong>resolving conflicts</strong> during code
              reviews
            </li>
          </ul>
        </div>
        <VideoBanner
          title="What you can expect from the workshop"
          videoId="NNXk_WJzyMI"
        />
        <section className="workshop-section content instructor-section">
          <div className="instructor-section-columns">
            <div>
              <h3>Hi, I'm Dr. Michaela Greiler.</h3>
              <p>
                Over the last 10 years, I extensively researched and developed
                test, build, and code review techniques and tools that boost the
                effectiveness and efficiency of engineering teams. I worked with{" "}
                <strong>all major product teams at Microsoft,</strong> such as
                Office, Windows, and Visual Studio to overcome pitfalls during
                code reviews.
              </p>
              <p>
                Since 2018, I also help companies outside of Microsoft boost
                their code review practices.
              </p>
              <p>
                I have a Ph.D. from Delft University of Technology in Software
                Engineering and received the{" "}
                <strong>Google Techmaker Award.</strong> I also run the{" "}
                <strong>Software Engineering Unlocked podcast.</strong> My
                research is published in several high-ranked scientific
                journals.
              </p>
            </div>
            <img
              alt="Dr. Greiler giving a speech"
              src="https://i1.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/03/Michaela_Speaking.jpg?w=939&ssl=1"
            />
          </div>
        </section>
      </section>
    </>
  )
}

export default WorkshopWhatsIncludedSection
