import * as React from "react"
import Video from "../Video/Video"
import "./VideoBanner.css"

const VideoBanner = props => {
  return (
    <section className="video-banner">
      <div className="content">
        <Video {...props} />
      </div>
    </section>
  )
}

export default VideoBanner
