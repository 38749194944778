import * as React from "react"
import "./Testimonial.css"

const Testimonial = ({ data, compact }) => {
  const meta = () => (
    <>
      <img
        alt=""
        className="testimonial__image"
        src={data.img}
        loading="lazy"
      />
      <span className="testimonial__meta-text">
        {data.name}
        <small>{data.job}</small>
      </span>
    </>
  )

  return (
    <>
      <article className="testimonial">
        <p
          className={`testimonial__review ${
            compact ? "testimonial__review--compact" : ""
          }`}
        >
          {data.content}
        </p>
        {data.href ? (
          <a className="testimonial__meta" href={data.href} rel="noopener">
            {meta()}
          </a>
        ) : (
          <div className="testimonial__meta">{meta()}</div>
        )}
      </article>
    </>
  )
}

export default Testimonial
