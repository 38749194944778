import * as React from "react"
import Testimonial from "./Testimonial/Testimonial"
import "./TestimonialsSection.css"

const testimonialData = [
  {
    content:
      "The code review workshop was extremely informative and engaging. Dr. Greiler presented the code review process from the perspective of the author, reviewer, and organization and gave clear ideas on how to improve each aspect.",
    href: "https://www.linkedin.com/in/timwardwebdeveloper/",
    name: "Tim Ward",
    img: "https://i2.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/tim-ward.jpg?fit=200%2C200&ssl=1",
    job: "Coordinator, Web Development at Seminole State College",
  },
  {
    content:
      "Michaela is a great example to show how beneficial innovation can be for software companies. Her ability to apply research findings to industry settings is impressive.",
    name: "Dr. Ana Pavia",
    img: "https://i0.wp.com/www.michaelagreiler.com/wp-content/uploads/2019/04/AnaPaiva.jpg?fit=179%2C216&ssl=1",
    job: "Professor at Univeristy of Porto, Portogual",
  },
  {
    content:
      "Perfect mix of theory, background, and practical. This is hard to get right!",
    href: "https://www.linkedin.com/in/davecottlehuber/",
    name: "Dave Cottlehuber",
    img: "https://i2.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Dave.jpg?fit=200%2C200&ssl=1",
    job: "Software Architect at SkunkWerks",
  },
  {
    content:
      "After attending the code review workshop, we were able to reduce the size of our code reviews and made the review process more consistent between projects.",
    href: "https://www.linkedin.com/in/marcus-%C3%B6sterberg-3840a5189/",
    name: "Marcus Österberg",
    img: "https://i1.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Marcus.jpg?fit=200%2C200&ssl=1",
    job: "Lead Frontend Developer, TeleClinic",
  },
  {
    content:
      "Thank you for leading the code review workshop earlier in the week. I found it to be informative and engaging. It encouraged discussion in a relaxed environment, which was valuable to me since a lot of the colleagues attending were in orgs that have different practices.",
    href: "https://www.linkedin.com/in/cironishiguchi/",
    name: "Ciro Nishiguchi",
    img: "https://i0.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Ciro-Nishiguchi.jpg?fit=200%2C200&ssl=1",
    job: "Chief Engineer at National Instruments",
  },
  /*
  {
    content:
      "I really recommend this code review workshop. It expanded my understanding of the code review process. Specifically, I understood much better the difference in perspectives between coder and reviewer.",
    href: "https://www.linkedin.com/in/staswish/",
    name: "Stas Wishnevetsky",
    img: "https://i0.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Stas.jpg?fit=200%2C200&ssl=1",
    job: "Backend Team Lead at Wix.com",
  },
  */
  {
    content:
      "Thanks a lot for the very interesting code review workshop! I feel that it emphasized many important points, of which I wasn't fully aware. The workshop was interactive and the studies you shared were interesting and inspiring.",
    href: "https://www.linkedin.com/in/tal-david-65a5565b/",
    name: "Tal David",
    img: "https://i2.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Tal-David.jpg?fit=200%2C200&ssl=1",
    job: "Software Developer at Wix.com",
  },
  /*
  {
    content:
      "Michaela's claim of 'making code reviews a superpower' definitely grabbed my attention when I first came across it. Having gone through the code review workshop, I can attest that this statement is not a hyperbole.\nBefore the code review training, I hadn’t given much thought about how to go about code reviews even though I knew its inherent value. The workshop resulted in me being more mindful of every aspect of code reviews starting from the language used for communication to the aspects of code to pay more attention to. I now take more time to clearly articulate the reasoning behind some of my comments clearly. This has resulted in a healthy exchange of ideas and then led to much better code created by the team in subsequent changes. That means, one good code review pays multiple times in the future allowing me to scale better. In other words, it is definitely a superpower.",
    href: "https://www.linkedin.com/in/vithungajendra/",
    name: "Vithun Kumar Gajendra",
    img: "https://i1.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/vithun.jpg?fit=200%2C200&ssl=1",
    job: "Principal Software Engineer at Expedia Group",
  },
  */
  {
    content:
      "Code-reviews and their expected results are very subjective. Putting them into a valuable and dignified context for all participants can be difficult. This workshop offers actionable ideas and principles that can help improve them for your team and yourself.",
    href: "https://www.linkedin.com/in/jakob-oberhummer-643b715b/",
    name: "Jakob Oberhummer",
    img: "https://i1.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/Jakob.jpg?fit=200%2C200&ssl=1",
    job: "Cofounder at Etonomy",
  },
  {
    content:
      "Michaela is a great communicator and expert on the topic! I enjoyed the code review training in general, especially provided examples of good and poor code review feedback. If you are interested or want to know more about code reviews best practices I’d definitely recommend her!",
    href: "https://www.linkedin.com/in/vasily-belolapotkov/",
    name: "Vasily Belolapotkov",
    img: "https://i1.wp.com/www.michaelagreiler.com/wp-content/uploads/2020/11/vasily-belolapotkov.jpg?fit=200%2C200&ssl=1",
    job: "Full-Stack Developer at Automattic",
  },
]

const TestimonialsSection = ({ reviewCount, offset, compact }) => {
  reviewCount = reviewCount ?? testimonialData.length
  offset = offset ?? 0

  return (
    <>
      <section
        className={`workshop-section testimonial-section ${
          compact ? "testimonial-section--compact" : "workshop-section--bg"
        }`}
      >
        <div className="content">
          <h2 className="visually-hidden">What Others Say</h2>
          {testimonialData
            .slice(offset, offset + reviewCount)
            .map(testimonial => (
              <Testimonial
                compact={compact}
                data={testimonial}
                key={testimonial.name}
              />
            ))}
        </div>
      </section>
    </>
  )
}

export default TestimonialsSection
